/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

//  @import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');

 @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;200;300;400;500;600&family=Barlow:wght@400;500;700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;400;500;600;700;900&display=swap'); // SAGA DEFAULT
//  @import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;600&display=swap');


// @import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap'); // 3EG (not very good, and doesn't have lower case characters)


/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// Swiper.js
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss/pagination';
@import "~swiper/css/navigation";

// * {
//   font-family: 'Lexend' !important;
// }

// * .condensed-text {
//   font-family: 'Antonio' !important;
// }

html {
  height: 100%;
}

// LAYOUT
.ordered-elements {
  position: relative;
  display: flex;
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.overlay {
  background: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
  // position: absolute;
  z-index: 999;
  // display: none;
  opacity: 1;
  transition: all 0.7s ease;
  // backdrop-filter: blur(7px);
  pointer-events: none;
  position: fixed;
}
.overlay-story {
  background: rgba(0,0,0,0);
}
.overlay-loading-fade-in {
  background: rgba(0,0,0,0.99);
}

.overlay-finished-loading {
  background: rgba(0,0,0,0) !important;
}

.foreground {
  z-index: 999999;
}


// TYPOGRAPHY

.ion-text-left {
  text-align: left;
}

.capitalize {
  text-transform: capitalize;
}

app-questions app-questions-slider {
  height: 100%;
}

app-onboarding, app-questions {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 80px;

    .has-skip-button & {
      bottom: 150px;
    }
  }
}


.large-icon {
  font-size: 64pt;
}


 /*

COLORS

debug colors, should make a static class
Deep purple = (delete) #AD0272
light purple (request) = #c6a0f0
light blue (success) = #a0bef0
blue (success plus) = #3880ff
light green (fresh) = #a0f0bc


Pleasant colors
76c4ae bewitched tree
7ce0f9 glass-gall
d3d2b5 brain sand
e1ceb1 magic powder

*/

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

.size-medium {
  max-width: 256px;
  max-height: 256px;
}

.size-large {
  max-width: 512px;
  max-height: 512px;
}

.relative {
  position: relative;
  width: 100%;
}

:root {
  --default-notch-inset: 24px;
  --default-status-bar-inset: 24px;
  --multiplier: 1.2; /* Define the multiplier as a variable */

  --safe-area-inset-top-iphone-test: 47px; /* used for testing */
}

.notch-safe {
  padding: var(--default-notch-inset);
  padding: calc(var(--multiplier) * env(safe-area-inset-top, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-right, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-bottom, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-left, var(--default-notch-inset))); /* Notch -> iOS 11.2+ */
}

.notch-safe-absolute {
  margin: var(--default-notch-inset);
  margin: calc(var(--multiplier) * env(safe-area-inset-top, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-right, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-bottom, var(--default-notch-inset))) 
           calc(var(--multiplier) * env(safe-area-inset-left, var(--default-notch-inset))); /* Notch -> iOS 11.2+ */
}

// PLAYER ONLY ADJUSTMENT SO TEXT IS NOT SO FAR DOWN
.program-wrapper .content .notch-safe {
  padding: var(--default-notch-inset);
  padding: calc(var(0.5) * env(safe-area-inset-top, var(--default-notch-inset))) 
           calc(var(0.5) * env(safe-area-inset-right, var(--default-notch-inset))) 
           calc(var(0.5) * env(safe-area-inset-bottom, var(--default-notch-inset))) 
           calc(var(0.5) * env(safe-area-inset-left, var(--default-notch-inset))); /* Notch -> iOS 11.2+ */
}

/* NOTCH SAFE ADJUSTMENTS TESTING */
/* START: TESTING Safe area for devices with notches */
/* Comment out the following styles before production */
// ##############################################################################
// ##############################################################################
// .notch-safe {
//   padding-top: calc(var(--multiplier) * var(--safe-area-inset-top-iphone-test)) !important;
// }
// .notch-safe-absolute {
//   margin-top: calc(var(--multiplier) * var(--safe-area-inset-top-iphone-test)) !important;
// }
// .notch-safe > .bgImage {
//   top: calc(var(--multiplier) * var(--safe-area-inset-top-iphone-test)) !important;
// }
// ##############################################################################
/* END: TESTING Safe area for devices with notches */

.content {
  padding-top: 32px;
}

.status-bar-overlays-web-view .status-bar-overlay-inset {
  padding-top: var(--default-status-bar-inset);
}

.status-bar-overlays-web-view .status-bar-overlay-inset-absolute {
  margin-top: var(--default-status-bar-inset);
}

// TODO: Igor Testing 2024 Dec 4
// TRYING TO FIGURE OUT IF THIS CSS IS NECESSARY
// .program-schedule-builder .programWeek.week-schedule {
// }

// I PUT IT BACK TO HOW IT WAS
.ion-padding-small {
  padding: 8px;
}

.ion-padding-large {
  padding: var(--ion-padding, 40px) !important;
}

.ion-margin-sides {
  margin: 0 16px !important;
}

.ion-margin-large {
  margin: 40px !important;
}

.ion-margin-small {
  margin: 8px;
}

.ion-margin-bottom-large {
  margin-bottom: 40px !important;
}

.ion-padding-bottom-large {
  --padding-bottom: var(--ion-padding, 40px) !important;
  padding-bottom: var(--ion-padding, 40px) !important;
}

.ion-padding-bottom-xlarge {
  --padding-bottom: 120px !important;
  padding-bottom: 120px !important;
}

.ion-padding-top {
  padding-top: var(--ion-padding, 16px) !important;
}

.ion-padding-top-24 {
  padding-top: 24px !important;
}

.ion-padding-left {
  padding-left: var(--ion-padding, 16px) !important;
}

.ion-padding-sides {
  padding-left: var(--ion-padding, 16px) !important;
  padding-right: var(--ion-padding, 16px) !important;
}

.ion-padding-top-large {
  --padding-top: var(--ion-padding, 40px) !important;
  padding-top: var(--ion-padding, 40px) !important;
}

.ion-padding-top-xlarge {
  --padding-top: var(--ion-padding, 80px) !important;
  padding-top: var(--ion-padding, 80px) !important;
}

.ion-padding-vertical {
  --padding-bottom: var(--ion-padding, 40px) !important;
  padding-bottom: var(--ion-padding, 40px) !important;
  --padding-top: var(--ion-padding, 40px) !important;
  padding-top: var(--ion-padding, 40px) !important;
}

.ion-padding-vertical-small {
  --padding-bottom: 24px !important;
  padding-bottom: 24px !important;
  --padding-top: 24px !important;
  padding-top: 24px !important;
}

.ion-margin-vertical-small {
  --margin-bottom: 24px !important;
  margin-bottom: 24px !important;
  --margin-top: 24px !important;
  margin-top: 24px !important;
}


.center-self {
  margin: auto;
}

.ion-background-cover {
  background-size: cover;
}

ion-button.academy-color-primary {
  // --color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
  // --border-color: var(--player-color-light);
  --ion-color-base: var(--player-color-primary) !important;
}

.swatch-color-primary {
  background: var(--player-color-primary, var(--ion-color-primary));
  width: 80px;
  height: 80px;
}


// COLORS
:not(.text-block) {
  &.medium {
    background: var(--player-color-medium, #a6a9b3);
  }
  
  &.danger {
    background: var(--player-color-danger, #F50B0B);
  }
  
  &.warn,
  &.warning {
    background: var(--player-color-warn, #EFAF0A);
  }
  
  &.success {
    background: var(--player-color-success, #50D615);
  }
  
  &.exceptional {
    background: var(--player-color-exceptional, #2a8403) !important;
    // border: 3px solid #000;
  }
}

.text-block {
  &.medium {
    color: var(--player-color-medium, #a6a9b3);
  }
  &.danger {
    color: var(--player-color-danger, #F50B0B);
  }
  &.warn,
  &.warning {
    color: var(--player-color-warn, #EFAF0A);
  }
  &.success {
    color: var(--player-color-success, #50D615);
  }
  &.exceptional {
    color: var(--player-color-exceptional, #2a8403) !important;
  }
}

.background-card {
  background: var(--ion-card-color, #FFF);
}
.background-clear {
  --background: transparent;    
  background: transparent;
}

.background-position-center {
  background-position: center;
}

.text-primary-contrast {
  color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
}

// LIST
.children-with-separators {
  span {
    border-left: 1px solid;
    padding-left: 8px;
    margin-left: 8px;
  }
  span:first-child {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
  span.minor {
    opacity: 0.3;
  }
}



ion-menu {
  --side-max-width: 200px;
}

.active {
  --background: var(--ion-color-light);
  --color: var(--ion-color-light-contrast);
}

ion-footer { 
  z-index: 999999999;
  background: var(--ion-card-color);
 }



.logo-wrapper {
  max-width: 120px;
  margin: auto;
  .dark { display: none; }
}
.logo-centered img { margin: auto; }

// IMAGE
.cover-image-max-height {
  max-height: 400px;
  overflow: hidden;
}

// .image-preview:not(.image-preview-full) ion-img {
//   max-width: 200px;
// }



@media only screen and (max-width: 768px) {
  .logo-wrapper {
    max-width: 80px;
    margin-top: 10px;
    &.ion-margin-bottom-large {
      margin-bottom: 20px !important;
    }
  }
}











.image-upload-row {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.image-upload-row ion-input {
  flex: 1;
}

.image-preview {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.image-preview img {
  width: 100%;
  max-width: 150px; /* Adjust this size for desktop as needed */
  height: auto;
  border-radius: 8px;
  border: 1px solid #ccc;
}














// EDIT IMAGE

.image-preview {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-preview.empty {
  border: 2px dashed var(--ion-color-medium);
  background-color: var(--ion-color-light);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-medium);
  font-size: 14px;
}

.empty-state ion-icon {
  font-size: 30px;
  margin-bottom: 5px;
}

.edit-overlay {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  pointer-events: none;
}

.image-preview .close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  color: red;
  display: none;
  cursor: pointer;
  z-index: 3;
}

.image-preview:hover .close-icon {
  display: block; /* Show on hover */
}




// COLOR SWATCH
.color-swatch {
  width: 24px;
  height: 24px;
  border: 1px solid #666;
  border-radius: 4px;
}

.color-picker-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.color-picker-row ion-input {
  flex: 1;
}

.color-swatch {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #ccc;
}


// BADGES
.badge {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
// .badge.danger {
//     background: var(--player-color-danger, #F50B0B);
// }

// .badge.warn {
//     background: var(--player-color-warn, #EFAF0A);
// }

// .badge.success {
//     background: var(--player-color-success, #50D615);
// }

ion-thumbnail,
ion-avatar {
  position: relative;
}

ion-thumbnail .badge,
ion-avatar .badge {
  position: absolute;
  right: 0;
  margin: 0;
  transform: translateX(50%);
  width: 12px;
  height: 12px;
}

// FAB
ion-fab > ion-fab-button {
  --background: var(--player-color-primary, var(--ion-color-primary, #3880ff));
}
.fab-horizontal-end,
.fab-horizontal-start {
  position: fixed;
  // bottom: 10px;
}

.relative-fab-container {
  position: relative;
}

ion-fab.relative-fab {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.relative-fab-add ion-fab {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

/* FAB POSITIONING FIX
 FOR STATUS BAR OVERLAY ON IPHONE AND ANDROID
 StatusBar.setOverlaysWebView({ overlay: true }) */
.fab-vertical-top {
  top: max(10px, var(--default-notch-inset)); // now will be 24px instead of default 10px
  top: max(10px, calc(1.1 * env(safe-area-inset-top, var(--default-notch-inset)))); // not sure what value we are getting on each device
}


// CARD

.box-shadow {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.card-corner-button {
  float: right;
  padding: 16px;
}

.card-with-button ion-card-title {
	display: inline-block;
	padding: 16px;
}


// ION-CALENDAR
.calendar-day.calendar-day-active::after {
  border: 1px solid var(--player-color-primary, #3880ff);
  background: var(--player-color-primary, #3880ff);
}
// CALENDAR
.calendar {
  text-align: center;
  &.calendar-full-width {
    width: 100%;
    .chip {
      width: 56px;
      height: 56px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .calendar {
    &.calendar-full-width {
      .chip {
        width: 32px;
        height: 32px;
      }
    }
  }
}


.chip {
  background-color: var(--player-color-danger, #F50B0B);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  &.success {
    background-color: var(--player-color-success, #50D615);
  }
  &.warn {
    background-color: var(--player-color-warn, #EFAF0A);
  }
  &.danger {
    background-color: var(--player-color-danger, #F50B0B);
  }
  &.future,
  &.past {
    background-color: #DDD;
    span {
      display: none;
    }
  }
  &.not-my-academy {
    opacity: 0.4;
    filter: grayscale(60%);
  }
  .primary-value {
    display: block;
  }
  .secondary-value {
    display: none;
  }
  &:hover {
    cursor: pointer;
    font-size: 9px;
    z-index: 69;
    // background: #000 !important;
    &.success {
      background-color: #3a9811 !important;
    }
    &.exceptional {
      background-color: #206203 !important;
    }
    &.warn {
        background-color: #b58508 !important;
    }
    &.danger {
        // border: 3px solid var(--player-color-danger, #F50B0B);
        background-color: #b00808 !important;
    }
    &.future,
    &.past {
        background-color: #DDD !important;
    }
    .primary-value {
        display: none;
    }
    .secondary-value {
        display: block;
        font-weight: bold;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .chip {
    &.future,
    &.past {
      background-color: #202020;
    }
  }
}

// TOOLTIP
.tooltip-wrapper + .tooltip-wrapper {
  margin-top: 10px;
}

.tooltip-wrapper {
    width: fit-content;
}

.tooltip{
    position: relative;
}

.tooltip::before {
    position: absolute;
    content: ' ';
    background-color: #010101;
    width: 15px;
    height: 15px;
    z-index: 500;
    opacity: 0;
    transition: all cubic-bezier(0.17, 0.67, 0.5, 0.71) 100ms;
    pointer-events: none;
}

.tooltip::after {
    content: attr(data-tooltip-text);
    white-space: nowrap;
    background-color: #010101;
    border-radius: 5px;
    color: white;
    position: absolute;
    text-align: center;
    z-index: 555;
    opacity: 0;
    transition: all cubic-bezier(0.17, 0.67, 0.5, 0.71) 100ms;
    pointer-events: none;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 .555555555555556rem;
}

.tooltip:hover::before, .tooltip:hover::after {
    opacity: 1;
}

.tooltip.t-top::before {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
}

.tooltip.t-top::after {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip.t-top:hover::before {
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip.t-top:hover::after {
    transform: translate(-50%, calc(-100% - 10px));
}

tooltip.t-left::before {
  top: 25%;
  left: 0;
  transform: translate(0, 0%) rotate(45deg);
}

.tooltip.t-left::after {
  top: 0;
  left: 0;
}

.tooltip.t-left:hover::before {
  transform: translate(calc(-100% - 5px)) rotate(45deg);
}

.tooltip.t-left:hover::after {
  transform: translate(calc(-100% - 10px));
}

.tooltip.t-right::before {
  top: 25%;
  right: 0;
  transform: translate(0, 0%) rotate(45deg);
}

.tooltip.t-right::after {
  top: 0;
  right: 0;
}

.tooltip.t-right:hover::before {
  transform: translate(calc(100% + 5px)) rotate(45deg);
}

.tooltip.t-right:hover::after {
  transform: translate(calc(100% + 10px));
}

.tooltip.t-bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
}

.tooltip.t-bottom::after {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip.t-bottom:hover::before {
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.tooltip.t-bottom:hover::after {
  transform: translate(-50%, calc(100% + 10px));
}


// VIDEO
// video
// {
//   max-width: 100%;
//   height: auto;
//   max-height: 100%;
// }

.videoResponsive {
    padding-bottom:56.25%;
    height:0;
    position:relative;
    padding-top:30px;
    overflow:hidden;
}
.videoResponsive iframe,
.videoResponsive object,
.videoResponsive embed {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}


////////////////////////////////////////////////
////////////////////////////////
// DARK COLOR SCHEME
@media (prefers-color-scheme: dark) {
  .logo-wrapper {
    .light { display: none; }
    .dark { display: block; }
  }
  ion-content{
    --ion-background-color:#1a1a1a;
  }
  ion-item:hover {
    --background: #171717;
  }
  ion-card:not(.ion-color),
  ion-item,
  ion-list,
  .list-md,
  .card {
    background: var(--player-card-darkMode, var(--ion-background-color));
    --background: var(--player-card-darkMode, var(--ion-background-color));
    --color: var(--ion-text-color);
  }
  ion-card:not(.ion-color) {
    background: rgba(var(--ion-background-color-rgb), 0.9);
  }

  app-program-preview {
    .programWeek ion-list {
      background: var(--ion-background-color);
    }
  }
}





////////////////////////////////////////////////
////////////////////////////////
// LIGHT COLOR SCHEME
@media (prefers-color-scheme: light) {
  .logo-wrapper {
    .light { display: block; }
    .dark { display: none; }
  }
  ion-content{
    --ion-background-color:#f4f5f8;
  }
  ion-menu ion-content {
    --ion-background-color:#fff;
  }
  ion-item:hover {
    // --background: #f4f5f8;
    --background: var(--ion-color-light);
    // --background: var(--ion-color-medium);
    // --color: var(--ion-color-medium-contrast);
  }
  ion-card:not(.ion-color),
  ion-item,
  ion-list,
  .list-md,
  .card {
    // background: #FFF;
    --background: #FFF;
  }
  ion-card:not(.ion-color) {
    background: rgba(var(--ion-background-color-rgb), 0.8);
  }
  ion-radio-group ion-item {
    --background:#f4f5f8;
  }
}



////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////
// ACADEMY
.academy {
  ion-button {
    // --color: var(--academy-color-primary);
    --border-color: var(--academy-color-primary);
    --ion-color-base: var(--academy-color-primary) !important;
    &.button-has-icon-only {
      --color: var(--academy-color-primary);
    }
  }
  .button-solid {
    --background: var(--academy-color-primary, #3880ff);
    --color: var(--player-color-primary-contrast, #fff);
  }
}


// DECOUPLED FROM ACADEMY CUSTOM COLORS
/*
.player {
  ion-button {
    // --color: var(--academy-color-primary);
    --border-color: var(--academy-color-primary);
    --ion-color-base: var(--academy-color-primary) !important;
    &.button-has-icon-only {
      --color: var(--academy-color-primary);
    }
  }
*/

.academy .masthead ion-button.button-has-icon-only, 
.player .masthead ion-button.button-has-icon-only,
ion-button.button-primary-contrast {
    // --color: var(--academy-color-light) !important; /* There was nowhere else this color was being used, or set */
    --color: var(--player-color-primary-contrast, #fff) !important;
}


.use-academy-branding {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  .text {
    color: var(--player-color-primary-contrast, #000);
  }
}






////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////`
// PROGRAM PLAYER

ion-content.player {
  // --background: var(--player-bg-image); // Try 1... does not work with setProperty
  // --background: var(--ion-background-image-light); // Try 2... this works
  --background: var(--player-color-bg, var(--ion-background-color)); // Was gonna Try 3... then I commented it out
}
app-program-builder-player-preview .player .player-content {
  background: var(--player-color-bg, var(--ion-background-color));
}

@media (prefers-color-scheme: dark) {
  app-program-builder-player-preview .player .player-content {
    background: var(--player-color-bg-darkMode, var(--ion-background-color));
  }
}

ion-content.player .page-content {
  --background: var(--ion-background-color);
}

.bgImage,
.program-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.bgDarken {
  background-color: #0005;
  background-blend-mode: darken;
}

.player .program-background {
  position: fixed;
  opacity: 0.3;
}

.program-bottom-fade {
  // background: linear-gradient(0deg, rgba(var(--player-color-bg-rgb),1) 0%, rgba(var(--player-color-bg-rgb),0) 100%); 
  background: linear-gradient(0deg, rgba(var(--ion-background-color-rgb),1) 0%, rgba(var(--ion-background-color-rgb),0) 100%); 
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 100;
  pointer-events: none;
}

.program-bottom-fade-large {
  background: linear-gradient(0deg, rgba(var(--ion-background-color-rgb), 1) 0%, rgba(var(--ion-background-color-rgb), 1) 60%,  rgba(var(--ion-background-color-rgb), 0) 100%);
  height: 130px;
}

.dark-background .program-bottom-fade {
  --custom-gradient-color: var(--enrollment-screen-background-color, #F00);
  background: var(--custom-gradient-color);
  // mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  mask-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%);
}

.program-builder {
  app-questions-slider .player {
    position: relative;
  }
  .program-bottom-fade {
    position: absolute;
    // display: none;
  }
}

.program-wrapper {
  z-index: 2;
  display: block;
  position: relative;
  z-index: 1;
  // margin-bottom: 160px; // temp way of make sure nothing was blocked by biometrics shelf
}

.top-left-controls {
  position: absolute;
  top: 0;
  right: 0;
}

.vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.vertical-align .question {
  text-align: center;
  width: 100%;
}

.header-with-buttons {
  display: flex;
  padding: 8px;
}

.inherit-border-radius {
  border-radius: var(--player-border-radius, var(--ion-border-radius));
  overflow: hidden;
}

ion-popover:has(app-habit-activated-popover)::part(content) {
  border-radius: var(--player-border-radius, var(--ion-border-radius));
}

.footer {
  // background: var(--player-color-light, #FFF);
  background: var(--ion-card-color);
  padding: 8px;
}
.footer-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  // padding-bottom: 8px;
  z-index: 99999;
}


.footer-bar-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 0px;
  z-index: 99999;
  background: var(--ion-background-color);
}

.footer-spacer {
  height: 90px;
}

.achievement-cstm-popover .popover-viewport{
  overflow: unset;
}

.achievement-popover {
  --background: var(--player-color-primary, #50D615);
  color: var(--player-color-light, #FFF);
  .achievement {
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 22px;
    }
    .fineprint {
      font-size: 18px;
    }
    ion-button {
      --color: var(--player-color-light, #FFF);
      --border-color: var(--player-color-primary, #FFF);
    }
  }
}

.achievement-cstm-popover{
  .achievement {
    display: flex;
    max-height: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .vertical-align {
      position: unset;
      top: unset;
      transform: unset;
    }
    .footer-bar {
      position: unset;
      bottom: unset;
    }
  }
}

.unselected-card {
    background: var(--ion-background-color);
    color: var(--player-color-medium);
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-color: var(--player-color-light);
}

.player {
  // --ion-background-color: var(--player-color-bg);
  // --background: none;

  .back-button {
    top: 16px;
    left: 16px;
    position: absolute;
  }

  .section-title {
    font-weight: 100;
    font-family: var(--ion-font-family-condensed);
    padding: 24px 16px 0 16px;
  }

  .header {
    padding: 24px 16px 0 16px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }

  .header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .page-center { background: transparent; }

  ion-card {
    border-radius: var(--player-border-radius, var(--ion-border-radius));
  }

  ion-item {
    &.rounded-input {
      border-radius: var(--player-border-radius, var(--ion-border-radius));
    }
  }

  ion-item-sliding {
    border-radius: var(--player-border-radius, var(--ion-border-radius));
  }

  app-activities-feed ion-label.ios {
    font-size: 14px;
  }

  ion-button:not(.ion-color-warning, .ion-color-danger) {
    // --color: var(--player-color-medium);
    --color: var(--ion-text-color);
    --border-color: var(--player-color-primary, var(--ion-color-primary));
    --ion-color-base: var(--player-color-primary, var(--academy-color-primary)) !important;
    --border-radius: var(--player-border-radius, var(--ion-border-radius));
    &.button-solid {
      --color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
      --background: var(--player-color-primary, var(--ion-color-primary));
    }
    &.button-outline {
      --color: var(--player-color-primary, var(--ion-color-primary));
    }
  }

  ion-button.overlay-video {
    --color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
  }


  .dark-background ion-button {
    --border-color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
    --color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
    &.button-outline {
      --color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
      --border-color: var(--player-color-primary-contrast, var(--ion-color-primary-contrast));
    }
  }

  &:not(.hasAlerts) .habit-grid.overlapMasthead {
    margin-top: -3em;
  }

  &.hasAlerts .overlapMasthead {
    margin-top: 0em !important;
  }

  app-habits-feed,
  .habit-feed {
    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .habit-grid.square-grid .icon {
    width: 100%;
    height: 100%;
  }

  app-habits-feed .icon img {
      width: 100%;
  }

  .completed-today {
    opacity: 0.6;
  }

  .completed-today {
    ion-label {
      text-decoration: line-through;
    }
    img {
        content: url("/assets/icons/complete.svg");
    }
  }

  .active,
  .highlighted-card {
    background: var(--player-color-primary, #000);
    color: var(--player-color-primary-contrast, #FFF);
    ion-card-title,
    ion-card-subtitle {
      color: var(--player-color-dark);
    }
    ion-img {
        filter: invert(1) brightness(2) saturate(0);
        // mix-blend-mode: color-dodge; // TODO: why the mix blend mode?? Was removed because it caused issues with 100% black
    }
  }

  .questions {
    &.dark-background {
      h1, p {
        color: var(--ion-text-color, var(--player-color-primary-contrast, #FFF));
      }
    }
    .equipmentItems {
      ion-card-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    ion-slide {
      h1 { margin: 20px; }
    }
    ion-card {
      // background: var(--ion-background-color); // TODO: Trying to increase affordance of clickable element
      color: var(--player-color-medium);
      box-shadow: none;
      border-width: 1px;
      border-style: solid;
      // border-color: var(--player-color-light, #CCC); // TODO: Trying to increase affordance of clickable element
      border-color: transparent;
      margin: 10px;
      &.ion-activatable {  
        opacity: 0.9;
      }
      &.active {
        opacity: 1;
      }
      ion-card-content {
        flex-grow: 3;
        padding-inline-start: 32px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-inline-end: 16px;
        &.ios {
          padding: 8px 32px;
        }
        ion-card-title {
          font-size: 20px;
        }
      }
      .card-check-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        ion-icon {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          opacity: 0.2;
        }
        .card-content {
          text-align: left;
          align-self: center;
        }
      }
      ion-card-title,
      ion-card-subtitle {
        color: var(--player-color-medium);
      }
    }
    .active,
    .highlighted-card {
      background: var(--ion-card-color);
      --color: var(--ion-text-color);
      opacity: 1;
      .card-check-box {
        ion-icon {
          opacity: 1;
          fill: var(--player-color-success, var(--ion-color-success, #2dd36f));
        }
      }
      ion-card-title,
      ion-card-subtitle {
        color: var(--player-color-dark);
      }
      border-color: var(--ion-color-light);
      box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    }
    .notifications {
      div {
        width: 100%;
      }
      ion-card .card-content {
        text-align: center;
      }
    }
  }

  .categories {
    display: flex;
    div {
      border-radius: 4px;
      padding: 8px;
      margin: 8px;
    }
  }

  // #container p, .container p {
  //   color: var(--player-color-dark);
  // }
  
  .swiper-pagination-bullet {
    background: var(--player-color-light);
    opacity: 0.4;
  }
  .swiper-pagination-bullet-active {
    background: var(--player-color-primary, var(--ion-color-primary));
    opacity: 0.8 !important;
  }

  .avatar-wrapper {
    height: 200px;
    max-height: 200px;
    margin: 40px;
  }
  
  .avatar {
      width: auto;
      float: left;
  }

  .add-activity {
    min-width: 56px;
  }
  
  // h1,
  // h2,
  // p { 
  //   color: var(--player-color-light);
  // }

  // .text-dark {
  //   h1,
  //   h2,
  //   p { color: var(--player-color-dark)};
  // }


  app-biometric-select,
  app-biometric-select-v1 {
    .overlay {
      background: rgba(0,0,0,0.6);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
      // display: none;
      opacity: 0;
      transition: all 0.7s ease;
      backdrop-filter: blur(7px);
      pointer-events: none;
      position: fixed;
    }
    .overlay-heads-up {
      position: absolute;
      top: 0;
      width: 100%;
      color: var(--ion-text-color);
      left: 0;
      display: block;
      z-index: 9999999999;
      text-align: center;
      font-weight: 100 !important;
      opacity: 0;
      transition: all 0.3s ease;
      pointer-events: none;
      position: fixed;
    }
  }
  
  .biometric {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: var(--player-color-bg, var(--ion-background-color));
    width: 100%;
    box-shadow: 1px 1px 24px #00000070;
    transform: translateY(0%);
    transition: all 1s ease;
    max-height: 60%;
    overflow-y: scroll;
    .logo-wrapper {
      max-width: 180px;
    }
    &.show-multiple {
      max-height: 100%;
      top: 0;
      text-align: center;
      // .swiper-wrapper {
      //   // justify-content: center;
      // }
      .question {
        width: 100%;
      }
    }
    .question {
      line-height: 36px;
      font-size: 18px;
      padding-top: 8px;
      font-weight: 700;
      cursor: pointer;
    }
    .icon {
      height: 40px;
      width: 40px;
      margin: auto;
    }
    .value {
      font-size: 24px;
      text-align: center;
    }
    .swiper-slide {
      flex-direction: column;
    }
    ion-card {
      margin: 0 4px;
      height: 64px;
      display: grid;
      align-items: center;
      width: 90%;
      // background: var(--player-card-lightMode);
    }
    &.stool ion-card {
      margin: 4px;
    }
    .biometric-values {
      .value::after {
        content: '\2014';
        padding-left: 8px;
      }
      ion-card { 
        margin-bottom: 8px;
        align-self: center;
      }
      p {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        font-size: 10px;
      }
    }
    .biometric-vertical-layout {
      ion-card {
        height: auto;
      }
      p {
        font-size: 16px;
        padding-right: 8px;
      }
      ion-card-content {
        padding: 4px 40px;
        justify-content: normal;
      } 
    }
    ion-card-content {
      padding: 4px;
    }
    .drag-shelf {
      height: 4px;
      background: #ccc;
      width: 64px;
      border-radius: 4px;
      position: absolute;
      left: 50%;
      top: 4px;
      transform: translateX(-50%);
      cursor: pointer;
    }
    h1.biometric-name {
      font-size: 14px;
      padding-left: 4px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-weight: lighter;
    }
    .thank-you-screen {
      // background: #50D615;
      // color: #FFF;
      .vertical-align {
        padding: 0 16px;
      }
      ion-icon {
        font-size: 64px;
      }
      h1 {
        font-size: 42px;
      }
      p {
        font-size: 22px;
      }
      .fineprint {
        font-size: 16px;
      }
    }
    ion-slides.center-content .swiper-wrapper {
      justify-content: center;
    }
  }

  @media (prefers-color-scheme: dark) {
    .biometric {
      background: var(--player-color-bg-darkMode, var(--ion-background-color));
    }

    .biometric ion-card {
      background: var(--player-card-darkMode, var(--ion-background-color));
    }
    
    .questions {
      .active,
      .highlighted-card {
        ion-card-title,
        ion-card-subtitle {
          color: var(--ion-text-color);
        }
      }
    }

  }

  // .readiness {}

  .achievement-popover {
    --background: var(--player-color-success, #50D615);
  color: var(--player-color-light, #FFF);
  }
  .achievement {
    --background: var(--player-color-success, #50D615);
  color: var(--player-color-light, #FFF);
  }
  
  ////////////////
  // REPORT CARD
  // .reports {
  //   ion-card-title {
  //     display: inline-block;
  //   }
  //   ion-icon,
  //   ion-button {
  //       float: right;
  //   }
  // }


  // EXPERIENCE POINTS
  .experience-points {
    padding: 8px;
    // background: var(--player-color-light);
    // color: var(--player-color-dark);
    text-align: center;
    margin: auto;
    width: 80%;
    border-radius: var(--ion-border-radius);
  }
  
  ////////////////
  // HABIT CARD
  .habit {
    .head { min-height: 220px;}
    ion-img {
        height: 160px;
        width: auto;
        float: left;
        margin-top: 24px;
    }

    .sessions,
    .copy { 
        // background: var(--player-color-bg);
        // color: var(--player-color-dark);
        padding: 10px;
        width: 100%;
        border-radius: 4px;
    }

    .sessions { 
        float: right;
        font-size: 18px;
        font-weight: bold;
        padding: 8px 16px;
    }
  }

  .programTile {
    margin: 40px auto 10px;
  }

  .end-quote {
    margin-top: 40px;
    margin-bottom: 120px;
    p {
      font-size: 24px;
      font-weight: 100;
      margin: 0;
    }
  }
  .end-element {
    margin-bottom: 120px;
  }

  @media only screen and (max-width: 768px) {
    .end-quote {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

} // end player wrapper

.biometrics-open,
.biometrics-maximized {
  app-biometric-select,
  app-biometric-select-v1 {
    .overlay {
      display: block;
      opacity: 1;
      pointer-events: all;
    }
    .overlay-heads-up {
      opacity: 0.4;
      &.show-multiple {
        display: none;
      }
    }
  }
}

// Fixes issues of ion-popover overlapping biometrics shelf
.biometrics-maximized ion-router-outlet {
  z-index: 100000 !important;
}

.biometric-fab {
  display: none;
}

.biometrics-minimized .biometric-fab {
  position: fixed;
  bottom: 8px;
  right: 8px;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.06);
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: block;
  background: #fff;
  z-index: 9999;
}

.scrolled-down .biometric {
  transform: translateY(100%);
  transition: all 1s ease;
}

.scrolled-down.biometrics-maximized .biometric {
  transform: translateY(0%);
  transition: all 1s ease;
}

.biometrics-minimized .biometric {
  transform: translateY(100%);
  transition: all 1s ease;
}

body:not(.biometrics-minimized) .last-element {
  padding-bottom: 200px;
}

.biometrics-open,
.biometrics-maximized {
  .fab-vertical-bottom,
  .fab-vertical-top,
  .developer-bar {
    display: none;
  }
}


// PROGRAM TILE
.programTile:not(.without-hover) {
  cursor: pointer;
  box-shadow: 0px 6px 8px 0px rgba(2, 2, 2, 0.12);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 320px;
  height: 320px;

  .bgImage {
    position: absolute;
  }

  .enrolled .bgImage {
    opacity: 0.5;
  }

  .content {
    z-index: 2;
    position: relative;
    color: #FFF;
    padding: 16px;
    background: rgba(0,0,0,0.3);
    height: 100%;
    text-align: center;
  }

  .price {
      opacity: 0;
  }

  .description {
      opacity: 0;
      margin: 0;
  }

  .level {
      background-color: var(--player-color-primary, #24c069);
      display: inline-block;
      padding: 4px 8px;
      margin: 8px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
  }
  .level-badge {
      // background-color: var(--player-color-primary, #24c069);
      display: inline-block;
      padding: 4px 8px;
      margin: 8px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
  }

  .cta {
      opacity: 0;
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      text-align: center;
  }
  .button {
      background-color: var(--player-color-primary, #24c069);
      display: inline-block;
      padding: 16px;
      border-radius: 8px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: bold;
      color: #FFF;
  }

  .title {
      font-size: 26px;
      font-weight: 900;
      padding: 0 30px;
      margin: 98px auto 0;
      text-align: center;
      line-height: 32px;
      letter-spacing: -0.5px;
      text-decoration: none;
      display: block;
      position: relative;
  }

  .equipment {
      color: #fff;
      opacity: 0.65;
      font-size: 13px;
      font-weight: 600;
      position: absolute;
      bottom: 25px;
      left: 30px;
      line-height: 18px;
      letter-spacing: -0.3px;
      text-transform: uppercase;
  }

  .duration {
      color: #fff;
      opacity: 0.65;
      font-size: 13px;
      font-weight: 600;
      position: absolute;
      bottom: 25px;
      right: 30px;
      text-align: center;
      line-height: 20px;
      letter-spacing: -0.3px;
      .dur {
          font-size: 28px;
          display: block;
          font-weight: 900;
      }
  }

  & * {
    transition: all .25s ease !important
  }

  & :hover,
  & .hover {
    & .content {
      background: rgba(0,0,0,0.6);
    }
    & .description,
    & .cta,
    & .price {
      opacity: 1;
    }
    & .level,
    & .level-badge {
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
    & .equipment,
    & .duration {
      opacity: 0;
    }
    & .title {
      font-size: 19px;
      margin-top: 25px;
      padding: 0 60px;
      line-height: 24px;
    }
  }
  
}









// Experience Points Toast

.experiencePoints {
  text-align: center;
  font-size: 18px;
  
}
// Doesn't work
// .toast-header {
//   margin-bottom: 4px;
//   font-weight: 400;
//   line-height: 20px;
//   font-size: 16px;
// }
// .toast-message {
//   font-size: 32px;
//   line-height: 40px;
// }





// LIMITS
.disabled {
  opacity: 0.3;
  // pointer-events: none;
}
.hidden {
  display: none;
}





// NOTIFICATIONS MANAGE
.player-mobile-preview .notifications-manage {
  max-width: 600px;
  margin: auto;
}
.notifications-manage {
  .questions ion-card ion-card-content {
    flex-grow: 3;
    padding-inline-start: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-inline-end: 10px;
  }

  ion-button.button-solid {
    --color: var(--ion-color-primary-contrast, #fff);
    --background: var(--ion-color-primary, #3880ff);
    --ion-color-base: var(--ion-color-primary, #3880ff) !important;
  }
}






.messages {
  display: flex;
  flex-direction:column-reverse;
  .message {
      padding: 8px;
      align-self: flex-start;    
      max-width: 680px;
      // width: 60%;
      > div {
          background: var(--ion-card-color);
          color: var(--ion-text-color);
          border-radius: 16px;
          padding: 8px;
          display: flex;
          justify-content: space-between;
      }
      &.my-reply {
          align-self: flex-end;
          > div {
              flex-direction: row-reverse;
          }
      }
      ion-label {
          padding: 0 24px;
      }
      ion-avatar {
          flex-shrink: 0;
      }
      .author {
          font-weight: 100;
      }
  }
}











  @media (prefers-color-scheme: light) {

  }

  @media (prefers-color-scheme: dark) {
    ion-content.player {
      // --background: var(--player-bg-image);
      // --background: var(--ion-background-image-dark);
      --background: var(--ion-background-color);
    }

    app-program-builder-player-preview .player-content {
      background: var(--ion-background-color);
    }

    
  
    // ion-content.player .page-content {
    //   --background: var(--ion-background-color);
    //   height: 100%;
    // }

    .player {
      // --ion-background-color: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), var(--player-color-bg);
      ion-button {
        --color: var(--player-color-light);
        &.button-solid {
          --color: var(--player-color-light);
          --border-color: var(--player-color-light);
        }
      }
      ion-button.button-outline {
        --color: var(--player-color-light);
      }
      .active,
      .highlighted-card {
        background-color: var(--player-color-primary, #FFF) !important;
        color: var(--player-color-light, #000);
      }
    }
        .invertIconsInDarkMode {
          app-habit-icon,
          .card > .icon,
          ion-item > .icon,
          img.icon,
          ion-card > .icon,
          ion-thumbnail > .icon {
            filter: invert(1) brightness(2) saturate(0);
          }
        }
    .invertIconsInDarkMode ion-img,
    .invertIconsInDarkMode ion-img.icon,
    .invertIconsInDarkMode ion-thumbnail,
    .mood ion-img.icon,
    .stool ion-img.icon,
    .biometric ion-img.icon,
    .player .biometric .icon {
      filter: invert(1) brightness(2) saturate(0);
    }
    .stool ion-img.icon {
      mix-blend-mode: color-dodge;
    } 
  }

  @media only screen and (min-width: 768px) {

    // OPTION 1 (mobile preview viewport on large desktops)
    // Known issues: Toast notifications not showing in the viewable area
    body.player-mobile-preview {
      background: #667075;
      // background: url('https://cdn.dribbble.com/users/136211/screenshots/2008336/bg-pattern-blue-sm.png');
      // background-image: url('https://wallpapercave.com/dwp2x/wp4047758.jpg');
      // background-size: cover;
    }
    .player-mobile-preview {
        app-program,
        app-program2 {
          // commented out because we are trying to move this into a .app-wrapper due to toast not showing up when this style is active
          z-index: 101;
          max-width: 480px;
          max-height: 900px;
          margin: auto;
          border-radius: 8px;
          box-shadow: 0px 4px 16px #444;
        }
    }

    // OPTION 2 (attempt to have a wrapper inside the player container)
    // Known issues: Biometric shelf incorrectly size and positioned in HYH Player
  // .app-wrapper {
  //   // copied over from .ion-page
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   display: flex;
  //   position: absolute;

  //   // copied from app-program2
  //   z-index: 101;
  //   max-width: 480px;
  //   max-height: 900px;
  //   margin: auto;
  //   border-radius: 8px;
  //   box-shadow: 0px 4px 16px #444;
  // }


  // OPTION 3 (Full width player with container for the main content area)
  .player-main-content {
    max-width: 800px;
    margin: auto;
  }
}






////////////////////////////////////////////////////////////////
///////////////////////////////////////////////
////////////////////////////////
/////////////////////
// REPORTING

.expandable_section {
  position: relative;
  min-width: 288px;
  margin: 8px;
  padding: 0;
  z-index: 999;
  background: var(--ion-card-color);
  border: 1px solid var(--ion-border-color);
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.06);
  // box-shadow: 3px 3px 3px #ccc;

  &.fixed_width {
    max-width: 960px;
  }

  .expandable_section_header {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
          flex-grow: 1;
          margin-left: 24px;
          margin-top: 16px;
          margin-bottom: 16px;
          text-transform: capitalize;
      }
      ion-icon {
          font-size: 40px;
      }
      .actions {
          opacity: 0.5;
      }
  }
  .expandable_section_progress {
      background-color: var(--ion-color-light);
      height: 4px;
      width: 100%;
      span {
          width: 0%;
          height: 100%;
          background-color: var(--ion-color-success);
          display: block;
      }
  }
  &.expanded {
    .section_total_progress {
      display: none;
    }
  }
  .expandable_section_content {
      padding-bottom: 40px;
  }
}

.expandable_section_progress {
  background-color: var(--ion-color-light, #DDD);
  height: 4px;
  width: 100%;
  margin-top: 8px;
  border-radius: 4px;
  overflow: hidden;
  span {
      width: 0%;
      height: 100%;
      background-color: var(--ion-color-medium);
      display: block;
  }
}

.categoryStat {
  background: var(--ion-color-step-300);
  border-radius: var(--ion-border-radius);
  margin: 16px;
  span {
    background-color: var(--ion-color-dark);
  }
}

.mainStat {
  .expandable_section_progress {
    height: 8px;
  }
  margin: 8px 8px 24px 8px;
}

.stat {
  padding: 4px;
  margin: 8px;
}

// DASHBOARD
.dashboard {
  .search-bar-with-buttons {
    display: flex;
    align-items: center;
    .inline-button {
      margin-left: 8px;
    }
  }
}









////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////
/////////////////////
// FORCE LIGHT MODE
.force-light-mode {
  p,
  ion-item,
  h1 { 
    color: #000;
  }
  ion-item {
    --background: #fff;
    --color: #fff;
    --border-color: #d9d9d9;
  }
  ion-item:hover {
    --background: #f2f2f2;
  }
  --color: black;
}












////////////////////////////////
////////////////////////////////
////////////////
// ION CARD 
ion-card {
  border-radius: var(--player-border-radius, var(--ion-border-radius));
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.07);
  &:not(.ion-color) {
    --background: var(--card-md-background-color);
    --color: var(--ion-text-color);
  }
}
.wrapper,
.card-padded,
.card-wrapper ion-card {
  margin: 10px !important;
}
ion-card.poster { padding: 0 !important; position: relative; }
ion-card.poster ion-card-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #fff;
  font-size: 18px;
  text-shadow: 1px 1px 1px black;
  line-height: 16px;
}
@media only screen and (min-width: 768px) {
  ion-card.poster ion-card-title {
    font-size: 24px;
  }
}
ion-card.empty { margin: 16px !important; }
// ion-card-empty .ion-card-title {
//   font-size: 24px;
// }
// ion-card {
//   font-size: 0px;
//   font-weight: 400;
// }

////////////////////////////////
////////////////
// CARD
@media (prefers-color-scheme: dark) {
  .cards {
      .card:not(.highlighted-card) {
        // background: rgba(var(--ion-background-color-rgb), 0.8);
        background: var(--player-card-darkMode, var(--ion-background-color));
        color: var(--ion-text-color);
        box-shadow: 1px 1px 8px rgb(49 13 0 / .7);
        &.card-ignore-shadow {
          box-shadow: inherit;
        }
      }
      ion-img {
          background: #494949;
      }
  }
}

@media (prefers-color-scheme: light) {
  .cards {
    .card:not(.highlighted-card) {
        // background: rgba(255,255,255, 0.8);
        background: rgba(var(--ion-background-color-rgb), 0.99);
        color: var(--ion-text-color);
        box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    }
    ion-img {
        background: #c4c4c4;
    }
  }
}

.cards {
  .card {
      display: flex;
      flex-direction: column;
      margin: 10px;
      border-radius: var(--player-border-radius, var(--ion-border-radius));
      overflow: hidden;
      cursor: pointer;
      .category {
        text-transform: capitalize;
        ion-badge, ion-button { float: right; }
      }
      .subcategory,
      .category {
        text-transform: capitalize;
      }
      h1 {
        margin-top: 8px;
        margin-left: 0;
        margin-right: 0;
        font-size: 28px;
        flex-grow: 1;
      }
  }
  .card-image {
    min-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 220px;
  }
  ion-img {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
  .card-content {
      margin: 24px;
  }
}

app-habits-feed .cards .card h1 {
  margin: 8px;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 0.02em;
}

// .fullscreen .card {
//   width: 100%;
// }

.cards-col {
  .card { 
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% - 20px);
  }
  .card-add {
    background: rgba(var(--ion-background-color-rgb), 0.3);
    border: 9px solid rgba(255,255,255,0.2);
    .card-content {
      margin: auto;
    }
  }
  ion-slide {
    height: auto;
  }
}

.cards-categories {
  .card {
    max-width: 600px;
    margin: 24px auto;
    h1 {
      color: #FFF;
      margin: 8px;
      font-size: 40px;
      text-shadow: 1px 1px 5px #000;
      font-weight: bolder;
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 576px) {
  ion-card{ margin: 8px; }
  .cards {
      .card {
          margin: 12px;
          flex-direction: column;
      }
      .card-image {
          width: 100%;
          height: 200px;
      }
      ion-img {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
      }
      .card-content {
          margin: 16px;
      }
  }

  .cards-categories {
    .card {
      margin: 8px;
      flex-direction: column;
      h1 {
        padding: 8px;
      }
    }
    .card-image {
      width: 100%;
      height: 160px;
    }
    .card-content {
        margin: 8px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dashboard {
    ion-card {
      margin-left: 4px;
      margin-right: 4px;
      ion-card-content {
        padding-left: 0;
        padding-right: 0;
        ion-badge {
          margin-left: 2px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) { 
  .cards-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .card {
      flex-basis: 300px;
    }
  }
}
  










////////////////////////////////
////////////////
/* CARD MODS */

.highlighted-card {
  background-color: var(--ion-color-primary);
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast, #FFF);
}
.highlighted-card.danger,
.highlighted-card.danger ion-card-header {
  background-color: var(--ion-color-danger);
}
.highlighted-card ion-icon { color: #fff; }
.highlighted-card ion-card-header { background-color: var(--ion-color-primary); }
.highlighted-card ion-card-subtitle { color: #FFF; }
.highlighted-card ion-card-title { color: #FFF; }
.highlighted-card ion-card-content { color: #FFF; }

ion-card.notification {

    background: var(--ion-color-warning);

    &[color="success"] {
      background: var(--ion-color-success, #0f0);
    }

    ion-card-title {
      font-size: 18px;
      font-weight: 400;
      opacity: 0.7;
      line-height: 140%;
  }
}


.card-color-primary {
  background-color: var(--ion-color-primary) !important;
}
.card-color-success {
  background-color: var(--ion-color-success) !important;
}
.card-color-warning {
  background-color: var(--ion-color-warning) !important;
}
.card-color-danger {
  background-color: var(--ion-color-danger) !important;
}





////////////////////////////////
///////////////
/* POPOVERS */

.medium-popover {
  &::part(backdrop) {
    background-color: var(--player-color-primary, var(--ion-color-primary));
  }
  &::part(content) {
    width: 350px;
    margin: auto;
    border-radius: var(--ion-border-radius);
  }
}

.large-popover,
.shareAcademyPopover {
  --ion-backdrop-color: var(--player-color-primary, var(--ion-color-primary));
  // &::part(backdrop) {
  //   background-color: red;
  // }
  &::part(content) {
    width: 95%;
    max-width: 500px;
    margin: auto;
  }
}

.xlarge-popover {
  &::part(backdrop) {
    background-color: var(--player-color-primary, var(--ion-color-primary));
  }
  &::part(content) {
    width: 95%;
    max-width: 1200px;
    margin: auto;
    height: 95%;
    max-height: 95%;
  }
}

.full-popover {
  &::part(content) {
    width: 98vw;
    height: 95%;
    max-height: 95%;
  }
}

.popover-fixed-vertical {
  &::part(content) {
    width: 95%;
    height: 95%;
    max-height: 95%;
  }
}

.fullscreen {
  &::part(backdrop) {
    background-color: var(--player-color-primary, var(--ion-color-primary));
  }
  &::part(content) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: calc(100% - 24px);
    border-radius: 0;
  }
}

.full-screen-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  --margin: 0;
}


// Popover Close Button
.popover_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 999999;
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.7;
  color: var(--ion-text-color);
  background: rgba(255,255,255,0.2);
  border-bottom-left-radius: var(--ion-border-radius);
  border-radius: var(--ion-border-radius);
}
.popover_close_overlaps_image {
  color: var(--player-color-primary-contrast, #FFF);
}
.popover_close:hover {
  opacity: 1;
}
.popover_close span {
  text-align: center;
  font-weight: bold;
  // text-transform: uppercase;
}

// Popover more + like buttons
.popover_more,
.popover_like {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  z-index: 999999;
  display: grid;
  align-items: center;
  cursor: pointer;
  color: var(--ion-text-color);
  background: var(--ion-background-color);
}
.popover_more {
  left: unset;
  right: 0;
}
.popover_more ion-icon,
.popover_like ion-icon {
  margin: auto;
}

// are these going to screw other popovers?
.popover-content {
  overflow: visible !important;
}
.popover-viewport {
  overflow: scroll;
}

.popover-viewport {
  .masthead {
    position: relative;
    h1 {
      position: absolute;
      z-index: 9;
      color: var(--player-color-primary-contrast, #FFF);
    }
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      object-position: top;
    }
    p {
      position: absolute;
      bottom: 0;
      background: rgba(255,255,255, 0.3);
      margin: 0;
      padding: 16px 10px;
      font-size: 14px;
      margin: 8px;
    }
  }
}

.popover-with-footer .popover-viewport {
  height: 100%;
  padding-bottom: 80px;
  white-space: pre-line;
}

.popover-footer {
  position: absolute;
  bottom: 0;
  background: var(--ion-background-color);
  width: 100%;
}

.button_top_right {
  top: 8px;
  right: 8px;
  width: auto;
}


// DIALOGS
.dialog {
  
  .dialog-header {
    padding: 16px;
    background-color: var(--ion-color-primary);
    .dialog-title {
      padding: 8px;
      color: var(--ion-color-primary-contrast);
    }
  }

  .billing-title {
    p {
      color: var(--ion-color-primary);
    }
  }

  .billing-body {
    .billing-freefeatures {
      background-color: rgba(var(--ion-color-success), 0.25);
      font-size: 12px;
      color: var(--ion-color-success);
      .billing-freefeatures-title {
        font-weight: bold;
      }
    }
  }
}





app-player-add {
  border-radius: var(--ion-border-radius);
}



////////////////////////////////
///////////////
// FILTERS
.subcategories ion-segment {
  display: flex;
  flex-wrap: wrap;
}








////////////////////////////////
//////////////////////
/* OTHER JAMBALAYA */

.shadow {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}
/* Fix issue on home/academy-landing-video topbar top shadow visible */
.notch-safe > .header-with-buttons.shadow {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 4px 5px 0px;
}

ion-note {
  padding: 0 0 16px 16px;
  display: block;
}
ion-note.ios {
  font-size: 12px;
}
.has-round-edges ion-note.md.hydrated {
  padding-right: 8px;
  padding-top: 8px;
}

ion-item { 
  width:100%;
}
ion-item > p {
  text-transform: capitalize;
}
.item-with-properties > span:not(:first-child):before {
  content: "|";
  padding: 4px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.page-center {
  max-width: 600px;
  margin: auto;
}
.page-center-lg,
.page-center-large {
  max-width: 960px;
  margin: auto;
}

.center-vertical,
.vertical-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 520px;
  margin: auto;
}

.vertical-center-flex {
  display: flex;
  align-items: center;
  height: 100%;
  .vertical-center-inner {
    margin: auto;
    max-width: 100%;
  }
}

.horizontal-center {
  justify-content: center;
}

// WRONGLY WORDED, BUT USED IN CODE.
.flex-columns {
  display: flex;
  flex-direction: column;
}
.flex-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// CORRECTED
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-row-center {
  align-items: center;
}
.flex-grow {
  flex-grow: 2;
}


.flex-space-around {
  justify-content: space-around;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-equal-width {
  & > div {
    flex: 1;
  }
}


.ion-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ion-column {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  flex: 1;
}


@media (min-width: 768px) {
  /* For tablets and larger screens */
  .ion-column {
    width: 48%; /* Two columns with a small gap */
  }
}

.full-height,
.fill-height,
.ion-full-height {
  height: 100%;
  min-height: 100%;
  background-size: cover;
}

.onboarding-questions-scroll-custom {
  max-height: fit-content;
  overflow-y: auto;
}

.height-fit-content {
  height: fit-content;
}

.height-80vh {
  height: 80vh !important;
}

.height-300 {
  height: 300px !important;
}

.height-240 {
  height: 240px !important;
}

.width-100 {
  width: 100%;
}

// TODO: Rename this style and create a real full width style
.full-width {
  width: 90%;
  max-width: 400px;
}

// TEMP solution until we do something about .full-width only taking up 90%
.full-width-100 {
  width: 100%;
  max-width: 500px;
}

@media only screen and (max-width: 768px) {
  .full-width-sm-down {
    width: 100%;
    max-width: 500px;
  }
}

.max-width-md {
  max-width: 576px;
}

@media (min-width: 1500px) {
  .max-width-md {
    max-width: 768px;
  }
}

// DISPLAY BLOCK
.block {
  display: block;
}
// SCROLLABLE
.scrollable-x {
    overflow-x: scroll;
}

.clickable {
  cursor: pointer;
}

.transparent {
  background: transparent !important;
  --background: transparent !important;
  box-shadow: none;
}
ion-list {
  border-radius: var(--player-border-radius, var(--ion-border-radius));
}
ion-list.transparent {
  background-color: transparent !important;
  ion-item {
    background-color: transparent;
    --background: transparent !important;
    --ion-item-background: transparent !important;
  }
}
ion-list.transparent-list {
  background-color: transparent !important;
}

.blur {
  -webkit-backdrop-filter: blur(15px); /* For Safari/WebKit */
  backdrop-filter: blur(15px);         /* Standard */
  filter: blur(15px);                  /* Fallback for unsupported browsers */
  transform: translate3d(0, 0, 0);     /* Force 3D acceleration */
}

.absolute {
  position: absolute;
}

.faded {
  opacity: 0.4;
}

.academy-primary-color {
  background-color: var(--academy-color-primary);
}

// TABLES
table.dataTable {
  width: 100%;
  background-color: #FFFFFF;
  border-collapse: collapse;
  border-width: 2px;
  border-color: var(--ion-color-primary);
  border-style: solid;
  color: #000000;
  &.compact {
    font-size: 10px;
  }
  td,
  th {
    border-width: 2px;
    border-color: var(--ion-color-medium);
    border-style: solid;
    padding: 5px;
  }
  td {
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
  }
  thead {
    background-color: var(--ion-color-medium);
    color: #FFF;
  }
  tr.highlight {
    background-color: rgba(var(--ion-color-primary-rgb), 0.25);
  }

  tr.duplicate {
    background-color: rgba(var(--ion-color-danger-rgb), 0.25);
  }

  tr.corrupt {
    background-color: rgba(var(--ion-color-warning-rgb), 0.25);
  }
}


@media (prefers-color-scheme: dark) {
  table.dataTable {
    background-color: var(--player-card-darkMode, var(--ion-background-color));
    color: var(--program-color-light, #FFF);
  }
}




////////////////////////////////
////////////////////////////
/* DIVIDER LINE (labeled) */
.divider {
  width: 100%;
  padding: 20px 0;
  div {
    background-color: #000;
    opacity: 0.1;
    width: 50%;
    height: 1px;
    margin: auto;

  }
}

.divider-labeled {
  div {
    position: absolute;
    top: 50%;
    width: 100%;
    background-color: #d9d9d9;
    height: 1px;
    transform: translateY(-50%);
  }
  span {
    display: block;
    margin: 8px auto;
    width: 4em;
    background-color: #fff;
    padding: 16px 8px;
    text-align: center;
    position: relative;
  }
  position: relative;
}

@media only screen and (max-width: 576px) {
  .vertical-center:not(.vertical-centered-forced) { 
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    transition: none;
    transform: none;
  }
}

.text-large { font-size: 32px; }

#vetrically-centered-container,
.vetrically-centered-container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  strong {
    font-size: 20px;
    line-height: 26px; }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c; }
  a { text-decoration: none; }
}



// ////////////////
// EDITABLE SECTION
// CHAT GPT GENERATED
// Improve Clickability in Masthead
// https://chatgpt.com/share/6722a39f-2f28-8010-b6e2-bb1de8d03b69

.editable-section {
  // display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid transparent; /* Placeholder border to ensure smooth outline transition */
  transition: border-color 0.3s ease;
  border-radius: var(--player-border-radius, var(--ion-border-radius));
  min-height: 40px;
  &.masthead {
    border-radius: var(--player-border-radius, 8px) var(--player-border-radius, 8px) 0 0;
  }
}

.editable-section::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  // border: 2px solid transparent; /* Default invisible border */
  border: 4px dashed transparent;
  border-radius: inherit; /* Match the parent’s border radius */
  transition: border-color 0.3s ease;
  pointer-events: none; /* Ensure this pseudo-element doesn't block clicks */
}

.editable-section:hover::before {
  border-color: #d9dee7; /* Change this to your desired color on hover */
}

.editable-section::after {
  content: "Click to edit";
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: var(--player-border-radius, var(--ion-border-radius));
  font-size: 0.875rem;
  opacity: 0.001;
  pointer-events: none;
  transition: background-color 0.3s ease, opacity 0.3s ease; /* Smooth text visibility change */
  z-index: 9999;
}

.editable-section-swippable::after {
  content: "Click or swipe";
}

.inner-container:hover .click-to-preview::after {
  content: "Click to preview story";
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
}

.editable-section-small::after {
  content: "";
  background-color: transparent;
}


.featured-program .programTile.editable-section::after,
.overlapMastheadWrapper .masthead.editable-section::after {
  bottom: 3.5em;
}

.editable-section.top-label::after {
  bottom: auto; /* Disable bottom positioning */
  top: 10px; /* Position at the top */
}

.editable-section .bgImage {
  transition: transform 0.3s ease; /* Smooth zoom in and out */
}

.editable-section:hover {
  // border-color: rgba(255, 255, 255, 0.7); /* Subtle white outline on hover */
  // border-color: #E9328D;
  border-color: transparent;
}

.editable-section:hover .bgImage
.editable-section.swiper-slide:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.editable-section:hover::after {
  background-color: rgba(0, 0, 0, 0.85); /* Darken the tooltip on hover */
  opacity: 1; /* Increase text visibility */
}

.editable-section-small:hover::after {
  background-color: transparent;
}

.player-mobile-preview .programTile.preview-tile {
  width: 447px !important;
  height: 447px !important;
}

// app-progress-bar
// {
//   &.editable-section {
//     display: block;
//   }
// }

.block {
  display: block;
}

.program.programTile.editable-section {
  border-width: 0;
}

.program.programTile.editable-section:hover .bgImage:after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 4px dashed #d9dee7;
  content: "";
  z-index: 5;
  border-radius: var(--player-border-radius, var(--ion-border-radius));
}

.player-mobile-preview .featured-program .programTile {
  margin: 0 auto;
}

.onboarding-slides ion-slide.editable-section {
  border-width: 0;
}
.onboarding-slides ion-slide.editable-section::before {
  border-radius: var(--player-border-radius, var(--ion-border-radius));
  z-index: 9999;
}
ion-item-sliding.editable-section-swippable:hover::before {
  border: 4px dashed #d9dee7;
  z-index: 11;
  border-radius: var(--player-border-radius, var(--ion-border-radius));
}

// light mode adjustments
@media (prefers-color-scheme: light) {
  ion-item-sliding.editable-section-swippable:hover::before {
    border-color: #d9dee7;
  }
}

////////////////////////////////////////////////
///////////////////////////////
// PROGRAMS MANAGER + PLAYER

.programWeek {
  // background-color:#fff;
  border-radius: 4px;
  padding: 4px 0 0 0;
  // box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  // ion-col {
  //   width: 14%;
  // }
  h1, p {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.card {
    // background: #f5f5f5;
    margin-bottom: 16px;
  }

  &.week {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  ion-card-header {
    padding: 8px;
  }

  ion-card-content, 
  .card-content-md {
    padding: 4px;
  }

  .dayOfWeek {
    margin: 0;
    // --background: white;
    // box-shadow: none;
    // border-right: 1px solid #eee;
    height: 100%;
    width: 100%;
    background: var(--ion-card-color);
    ion-item {
        --color: var(--ion-text-color);
        border-radius: 8px;
        margin-top: 8px;
        --inner-padding-end: 0;
        ion-note {
            --color: var(--ion-text-color);
        }
    }
    .button {
      --padding-end: 0.2em;
      --padding-start: 0.2em;
      margin-left: 0;
    }
  }

  .habitTypeColored {
    .strength,
    .workout {
        --background: #d3a0f0;
    }
    .restday {
        --background: lightgray;
    }
    .nutrition {
        --background: #498e66;
    }
    .habit {
      --background: #7e9dd2;
    }
    .solution {
      --background: #d27e9f;
    }
    .file {
        --background: #a2968b;
    }
    .photo {
        --background: #554f7b;
    }
    .updateMeasurements {
        --background: #5e4961;
    }
    .corrupt {
      --background: #d20000 !important;
    }
  }
}

app-program-select .programWeek app-habit-icon {
  max-width: 40px;
  height: 40px;
  width: 40px;
}

.programDetails .programWeek .programWeek {
  padding: 40px 0 40px 0;
}

.programWeek.slides {
  padding: 0;
  ion-card.dayOfWeek {
    margin-left: 10px;
  }
}



@media only screen and (max-width: 768px) {
  .programWeek {
    &.week {
      grid-template-columns: 1fr;
      display: grid;
      grid-column-gap: 10px;
    }
  }
}



////////////////////////////////////////////////
///////////////////////////////
// PROGRAMS
.programs {
  .program-image {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

@media only screen and (max-width: 768px) {
  .programs {
    .programs-row {
      flex-direction:  column-reverse;
    }
    .program-image {
      height: 200px;
    }
  }
}


////////////////////////////////////////////////
///////////////////////////////
// EVENTS
.events {
  .cards {
      display: flex;
      .event {
        width: 33%;
        color: #FFF;
        text-shadow: 1px 1px 8px rgba(0,0,0,0.5), 1px 1px 1px rgba(0,0,0,0.5);
        margin: 1%;
        min-height: 300px;
        background-size: cover;
        overflow: hidden;
        border-radius: 8px;
        cursor: pointer;
        .event_note {
          margin-bottom: 8px;
          margin-top: 0;
      }
      }
  }
}

@media only screen and (max-width: 768px) {
  .events {
    .cards {
      flex-direction: column;
      .event {
        width: 98%;
      }
    }
  }
}

.event {
  font-weight: 700;
  position: relative;

  .event_inner {
      margin: 40px;
      z-index: 999;
      position: relative;
  }
  .event_days {
      font-size: 24px;
      font-weight: 800;
  }
  .event_month {
      font-size: 11px;
      font-weight: 100;
      margin-bottom: 8px;
      text-transform: uppercase;
  }
  .event_note {
      font-size: 12px;
      font-weight: 800;
      background: var(--academy-color-primary);
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      text-shadow: none;
      text-transform: uppercase;
      margin: 8px 0;
      color: #FFF;
  }
  .event_name {
      font-size: 28px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 4px;
  }
  .event_location {
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 6px;
  }
  .event_description {
      font-size: 14px;
      font-weight: 100;
  }
}














////////////////////////////////
//////////////////////
/////////////
// HELPERS //
.centered-controls {text-align: center;}
.remaining {
    font-size: 64px;
    display: none;
}
.units {
    text-transform: uppercase;
    font-weight: 100;
    font-size: 12px;
    display: none;
}


////////////////////////////////
//////////////////////
/////////////
// Counter timer circle
.circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }
  .circle {
    stroke: #4CC790;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  #svg circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: #e7e7e7;
    stroke-width: 1em;
  }
  #svg #bar {
    stroke: var(--player-color-primary, #3880ff);
  }
  #counter {
    display: block;
    height: 200px;
    width: 200px;
    margin: 2em auto;
    box-shadow: 0 0 1em black;
    border-radius: 100%;
    position: relative;
  }
  #counter:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    box-shadow: inset 0 0 1em black;
    content: attr(data-pct)"s";
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 2em;
    text-shadow: 0 0 0.5em black;
    text-align: center;
  }
  #counter.percent:after {
    content: attr(data-pct)"";
  }
  .strikethrough {
    text-decoration: line-through;
  }
  // SMALL COUNTER
  .small-counter #svg circle {
    stroke-width: 10px;
  }
  #counter.small-counter {
    height: 100px;
    width: 100px;
    box-shadow: none;
    margin: 0em auto;
  }

  #counter.small-counter:after {
    height: 100px;
    width: 100px;
    left: 0;
    top: 0;
    box-shadow: none;
    margin-top: 0;
    margin-left: 0;
    line-height: 100px;
    font-size: 1.4em;
    text-shadow: none;
  }

  @media (prefers-color-scheme: dark) {
    #svg circle {
      stroke: #696969;
    }
  }








////////////////////////////////
////////////////////////
////////////////
// LABELS & VALUES
.xlarge-values {
  .label {
      opacity: 0.5;
      font-size: 14px;
      text-align: center;
      overflow-wrap: normal;
  }
  .value {
      // font-family: Barlow-Medium;
      font-size: 36px;
      text-align: center;
  }
  @media only screen and (max-width: 576px) {
    .value {
      font-size: 24px;
    }
  }
}
.units {
    text-align: center;
}
.phase { 
    padding-bottom: 24px;
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 24px;
}
.phase>div {
    display: flex;
    flex-direction: column;   
  }
  .phase.flex-direction-column-reverse>div {
  flex-direction: column-reverse;   
}
.fineprint {
  opacity: 0.6;
  font-size: 12px;
}
.finerprint {
  opacity: 0.2;
  font-size: 16px;
  font-weight: lighter;
}







////////////////////////////////
////////////////////////
////////////////
// ERROR CODES AND 404's
.ooops,
.loading {
  ion-img {
      max-width: 490px;
      margin: auto;
      mix-blend-mode: darken;
  }
}










////////////////////////////////
/////////////////////////
////////////////
// ADMIN & GOD MODES
// #adminBtn {
//   position: fixed;
//   top: 10px;
//   right: 10px;
//   z-index: 1000;
//   --background: white !important;
//   --ion-color-base: white !important;
// }

// @media (prefers-color-scheme: dark) {
//   #adminBtn {
//     --color: #000;
//   }
// }


// ion-header.global ion-toolbar {
//   --background: #000;
//   --color: #39FF14;
// }

:root {
  --platform-color: mediumvioletred;
}

.global {
  ion-toolbar,
  &ion-footer ion-button {
    --background: black;
    // --color: #33FF33;
    --color: var(--platform-color);
  }
  // ion-content {
     
  // }  

  ion-item,
  ion-icon,
  p {
    color: var(--platform-color) !important;
  }
}

// ADMIN
ion-item.admin,
.admin ion-icon,
.admin ion-note  {
  color: var(--platform-color);
}

.activity-completed-indicator {
  width: 16px;
  height: 16px;
  font-size: 9px;
  display: block;
  border-radius: 50%;
  border: 2px solid #F2F2F2;
  margin: 8px;
  top: 0px;
  z-index: 1;
  &.activity-completed {
    border: 2px solid var(--player-color-primary, #3880ff);
    background: var(--player-color-primary, #3880ff);
    span { 
      color: var(--player-color-primary-contrast, #FFF);
      text-align: center;
      display: block;
    }
  }
}

app-habits-feed,
.habit-feed,
app-habits-feed-preview,
app-program-builder-player-preview {
  app-habit-icon,
  .card > .icon {
      padding-left: 16px;
      padding-top: 0px;
  }
  ion-item > .icon {
    margin-left: 16px;
  }
}

.activity-check-list {
  app-habit-icon,
  .card > .icon {
      padding-left: 24px; // 48px
      padding-top: 0px;
  }
  .activity-completed-indicator {
    width: 24px;
    height: 24px;
    font-size: 13px;
    line-height: 20px;
    margin: 12px;
    text-align: center;
  }
  &.show-completed-count-badge {
    .activity-completed-indicator {
      margin: 16px;
    }
    app-habit-icon,
    .card > .icon {
        padding-left: 0;
    }
  }
}

// SINGLE CHECK MARK
.activity-completed-indicator.activity-completed-single::after {
	content: "\2713";
	color: #FFF;
  font-size: 12px;
  line-height: 12px;
}

app-habits-feed .grid-view {
  .activity-completed-indicator {
    position: absolute;
  }
  app-habit-icon,
  .card > .icon {
      padding-top: 16px;
  }
}
.grid-view .activity-check-list {
  app-habit-icon,
  .card > .icon {
      padding-top: 16px;
  }
}

app-habits-feed .activity-check-list {
  ion-item > .icon {
    margin-left: 48px;
  }
}



















// PROGRAM BUILDER PLAYER PREVIEW

.program-builder {
  .player-view {
  
    .featured-program .programTile {
      top: 0 !important;
    }
  
    .programDetails {
      padding: 0 20px;
      margin: 100px 0 !important;
    }
  
    app-questions-slider {
      .questions-slides-wrapper {
        &.ion-padding-bottom-xlarge {
          padding-bottom: 0 !important;
        }
        // max-height: 100vh;
        overflow: visible;
        .force-scrolling {
          overflow: visible;
        }
      }
    }
  
    .footer-bar {
      position: relative !important;
    }
  
    // .full-height, .fill-height, .ion-full-height {
    //   height: 80vh;
    // }
  }
  .outer-container {
    height: auto !important;
  }
}


// SPINNER
ion-spinner.largeSpinner {
  width: 100px;
  height: 100px;
}

// CONFETTI
@keyframes confetti {
  0% { transform: translateY(-100vh) rotate(0deg); }
  100% { transform: translateY(100vh) rotate(360deg); }
}

.confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #ff0;
  animation: confetti 2s linear forwards;
  z-index: 1000;
}




@keyframes explode-outwards {
  0% { transform: translate(0, 0) scale(0) rotate(0deg); opacity: 1; }
  100% { transform: translate(var(--x), var(--y)) scale(1) rotate(360deg); opacity: 0; }
}

.confetti-piece {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ff0; /* Yellow color for confetti */
  animation: explode-outwards 1s ease-out forwards;
  z-index: 1000;
  pointer-events: none;
}



//
//
// DEBUG

.hidden-until-hover:hover {
  .hidden {
    display: block !important;

  }
}
.developer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  .dev-property{
    background-color: var(--ion-color-primary, #3880ff);
    color: var(--ion-color-primary-contrast, #FFF);
    text-align: center;
    padding: 4px;
    font-size: 8px;
  }
  .dev-property-secondary {
    background-color: var(--ion-color-secondary, #3dc2ff);
  }
  .dev-property-danger {
    background-color: var(--ion-color-danger, #ff3d6a);
  }
  // .dev-firebase-project-id {}
}
.debug-values {
  border-bottom: 4px solid #ccc;
  >div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    .label {
      opacity: 0.5;
      font-size: 14px;
    }
    .value {
      font-weight: bold;
    }
  }
}
.enable-developer-mode {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  // background: yellow;
  // opacity: 0.1;
}
.enable-developer-mode-in-story {
  position: absolute;
  width: 160px;
  height: 60px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.developer-mode-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 48px;
  height: 48px;
}
.developer-mode-enabled {
  .enable-developer-mode {
    background: yellow;
    opacity: 0.2;
  }
}

.coder_debug_info p {
  background: black;
  color: #4eff00;
}

// DEBUG LOG JSON Component
.debug-json-component {
  .key,
  .indent {
      color: blue;
  }

  .highlight {
    color: red !important;
  }
  
  .key::after {
      content: ": ";
      display: contents;
  }
}

.background-red {
  background: red !important;
}

.background-green {
  background: green !important;
}

// BUG FIX POPOVER ERROR
// ERROR: "Found a 'popover' attribute with an invalid value." - framework-delegate
// https://github.com/valor-software/ngx-bootstrap/issues/6544
// BUG FIX EXISTS: This bug is fixed already. You should update to version 6.5.2 or later.
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}